<style src="./index.less" lang="less" scoped rel="stylesheet/less"></style>
<template src="./index.html"></template>

<script>
export default {
    name: "baseUse",
    props: ["type"],
    mounted() {},
    activated() {},
    methods: {
        goHome(){
            this.$router.push({ name: "index"});
        },
        reset() {
            this.useData = {
                store: "",
                name: "",
                mobile: "",
                referrer: "",
            };
        },
        submit() {
            let that = this;
            let _useData = that.useData;
            console.log(_useData, that.$publicFn);
            if (_useData.store.length < 4) {
                that.$publicFn.messageError("店铺名称不能为空且至少四位！");
                return;
            }
            if (_useData.name.length <= 0) {
                that.$publicFn.messageError("联系人姓名不能为空！");
                return;
            }
            if (_useData.mobile.length <= 0) {
                that.$publicFn.messageError("联系人电话不能为空！");
                return;
            }
            console.log(!that.$publicFn.isPhone(_useData.mobile));
            if (!that.$publicFn.isPhone(_useData.mobile)) {
                that.$publicFn.messageError("联系人电话不合法！");
                return;
            }
            let data = {
                method: "post",
                isToken: false,
                url: "ext/extnetagent/save",
                data: _useData,
                successCallback(res) {
                    console.log(res.data.data);
                    that.$publicFn.messageSuccess(
                        "提交成功，我们会尽快与您联系！"
                    );
                    that.$publicFn.userBoxClose();
                },
            };

            that.$getNetWork._axios(data);
        },
    },
    data() {
        return {
            useData: {
                store: "",
                name: "",
                mobile: "",
                referrer: "",
            },
        };
    },
};
</script>

