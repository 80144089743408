//this.$store.dispatch('setShowSelectAddress', true); 设置
//this.$store.getters.getShowSelectAddress 读取
export default {
  namespaced: true,
  state:{
    data:{},

  },
  //定义对状态值的操作，增删改查
  // this.$store.commit('方法名', 参数)
  mutations: {
    SET_DATA(state,_data) {
      state.data[_data.name]= _data.data;

      // if(state.data.some(function(item){ return item==_data.name})){
      //
      // }
      // else{
      //
      // }
      // for(let i in _data){
      //     state[i] = _data[i];
      // }

    },
  },

  //this.$store.dispatch('方法名', 参数);
  actions: {
    setData({commit}, _data) {
      commit('SET_DATA', _data);
    },
  },

  //this.$store.getters.*** 读取
  getters: {
    // get_show:function(state){
    //   return state.show;
    // },
    getData: (state) => state.show,
  }
}
