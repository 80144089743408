import Vuex from 'vuex';
import Vue from 'vue';
import a from "./a"

Vue.use(Vuex)

//this.$store.dispatch('setShowSelectAddress', true); 设置
//this.$store.getters.getShowSelectAddress 读取

const store = new Vuex.Store({
  modules:{
    pageConfig:a
  },
  state: {
    
    //设置语言   zh_CN 中文    en_US 英文
    languageType:'zh_CN',
  //  tags
    tagsData:[],
    tagsArr:[],
  //  一级菜单
    sysapplicationList:[],
  //  二级菜单
    sysmenuList:[],
    // 模块切换选择
    sysapplicationListSelect:[],
  //  每页显示多少条
    pageSize:20,
    themeStyleConfig:null,
    baseLoadModal:false,
  },
  mutations: {
    //设置语言
    SET_languageType(state, languageType) {
      state.languageType = languageType;
    },
    //  tags
    SET_tagsData(state, tagsData) {
      state.tagsData = tagsData;
    },
    SET_tagsArr(state, tagsArr) {
      state.tagsArr = tagsArr;
    },
    //  一级菜单
    SET_sysapplicationList(state, sysapplicationList) {
      state.sysapplicationList = sysapplicationList;
    },
    //  二级菜单
    SET_sysmenuList(state, sysmenuList) {
      state.sysmenuList = sysmenuList;
    },
    // 模块切换选择
    SET_sysapplicationListSelect(state, sysapplicationListSelect) {
      state.sysapplicationListSelect = sysapplicationListSelect;
    },
    //  每页显示多少条
    SET_pageSize(state, pageSize) {
      state.pageSize = pageSize;
    },
    //  themeStyleConfig
    SET_themeStyleConfig(state, themeStyleConfig) {
      state.themeStyleConfig = themeStyleConfig;
    },
    //  baseLoadModal
    SET_baseLoadModal(state, baseLoadModal) {
      state.baseLoadModal = baseLoadModal;
    },

  },
  actions: {
    //设置语言
    set_languageType({commit}, languageType) {
      commit('SET_languageType', languageType);
    },
  //  tags
    set_tagsData({commit}, tagsData) {
      commit('SET_tagsData', tagsData);
    },
    set_tagsArr({commit}, tagsArr) {
      commit('SET_tagsArr', tagsArr);
    },
    //一级菜单
    set_sysapplicationList({commit}, sysapplicationList) {
      commit('SET_sysapplicationList', sysapplicationList);
    },
    //二级菜单
    set_sysmenuList({commit}, sysmenuList) {
      commit('SET_sysmenuList', sysmenuList);
    },
    // 模块切换选择
    set_sysapplicationListSelect({commit}, sysapplicationListSelect) {
      commit('SET_sysapplicationListSelect', sysapplicationListSelect);
    },
    //  每页显示多少条
    set_pageSize({commit}, pageSize) {
      commit('SET_pageSize', pageSize);
    },
    //  themeStyleConfig
    set_themeStyleConfig({commit}, themeStyleConfig) {
      commit('SET_themeStyleConfig', themeStyleConfig);
    },
    //  baseLoadModal
    set_baseLoadModal({commit}, baseLoadModal) {
      commit('SET_baseLoadModal', baseLoadModal);
    },
  },
  getters: {
    //设置语言
    get_languageType: (state) => state.languageType,
  //  tags
    get_tagsData: (state) => state.tagsData,
    get_tagsArr: (state) => state.tagsArr,
    //一级菜单
    get_sysapplicationList: (state) => state.sysapplicationList,
    //二级菜单
    get_sysmenuList: (state) => state.sysmenuList,
    // 模块切换选择
    get_sysapplicationListSelect: (state) => state.sysapplicationListSelect,
    //  每页显示多少条
    get_pageSize: (state) => state.pageSize,
    //  themeStyleConfig
    get_themeStyleConfig: (state) => state.themeStyleConfig,
    get_baseLoadModal: (state) => state.baseLoadModal,
  }
})
export default store;
