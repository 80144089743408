import Vue from 'vue'
import store from '../store';

import { router } from '../router';

export default {
  //错误提示
  messageError(msg) {
    Vue.prototype.$Message({
      message: msg,
      type: 'error',
      duration:5000,
      center:true,
      showClose: true,
      customClass:"customClassMessageError",
    })
  },
  // 成功提示
  messageSuccess(msg) {
    Vue.prototype.$Message({
      message: msg,
      type: 'success',
      duration:2000,
      showClose: true,
      center:true,
      customClass:"customClassMessageSuccess",
    })
  },
  userBoxOpen(){
    // store.dispatch("set_use", true);
  },
  userBoxClose(){
    store.dispatch("set_use", false);
  },
  //数字转英文月份
  getNumToMonth(_n) {
    let r = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return r[_n];
  },

  // 移动端系统判断
  isSystem() {
    if (navigator.userAgent.indexOf("CordovaAndroid") > -1) {
      // localStorage.setItem('systemType','android');
      return 'android';
    }
    else if (navigator.userAgent.indexOf("CordovaIOS") > -1) {
      // localStorage.setItem('systemType','ios');
      return 'ios';
    }
    else {
      localStorage.setItem('systemType', 'other');
      return 'other';
    }
  },
  //检查微信内核浏览器
  isWeixin() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  },

  //  邮政编码校验
  isZipCode(_val) {
    return /^[1-9]{1}[0-9]{5}$/.test(_val);
  },
  //  手机号校验
  isPhone(_val) {
    return /^1[34578]\d{9}$/.test(_val);
  },
  
  isNumber(_val) {
    return /^[0-9]*$/.test(_val);
  },
  isEmail(_val) {
    return /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(_val);
  },
  //判断整数和两位小数
  isNumber_xx(_val) {
    return /^\d+(\.\d{1,2})?$/.test(_val);
  },
  //  千元分隔符
  isQianfen(_val) {
    console.log(_val);
    return _val.toString().replace(/\B(?=(\d{3})+$)/g, ',');
  },
  //添加千位分隔符，并保留2位小数
  isMoneyQianfen(s) {

    if (/[^0-9\.]/.test(s)) return "invalid value";
    s = s.toString().replace(/^(\d*)$/, "$1.");
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
    s = s.replace(".", ",");
    var re = /(\d)(\d{3},)/;
    while (re.test(s)) {
      s = s.replace(re, "$1,$2");
    }
    s = s.replace(/,(\d\d)$/, ".$1");
    return s.replace(/^\./, "0.")
  },
  getDateTime(fmt) {
    let myDate = new Date();
    let o = {
      "M+": myDate.getMonth() + 1, //月份
      "d+": myDate.getDate(), //日
      "H+": myDate.getHours(), //小时
      "m+": myDate.getMinutes(), //分
      "s+": myDate.getSeconds(), //秒
      "q+": Math.floor((myDate.getMonth() + 3) / 3), //季度
      "S": myDate.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (myDate.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
  },
  //获取当前网站域名
  getLocalhostPaht() {
    return "39.105.19.175:9989";
  },
  isMobileSystem() {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
      //alert(navigator.userAgent);
      return 'ios';
    } else if (/(Android)/i.test(navigator.userAgent)) {  //判断Android
      //alert(navigator.userAgent);
      return 'android';
    } else { //pc
      return 'other';
    }
  },
  // 日期对象转字符串 2018-07-25 09:30:34
  dateTimeToString(fmt, date) {
    console.log(fmt, date);
    let o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      "S": date.getMilliseconds() //毫秒
    };

    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    console.log(fmt);
    return fmt;
  },

  
  //判断是否iphoneX
  isIphoneX() {
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var ratio = window.devicePixelRatio || 1;
    var screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio
    };
    if (iOS && screen.width == 1125 && screen.height === 2436) {
      return true;
    }
    else {
      return false;
    }

  },
  //判断微信下的iphonex
  isWeixinIphonex() {
    if (this.isWeixin()) {
      if (this.isIphoneX()) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  },
  //身份证
  isIdcard(_val) {
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(_val);
  },

  //解决ios android date日期bug
  isNewDate(_val) {
    // alert(this.isSystem());
    let _date = new Date(_val.toString());
    if ((_date.getFullYear() + "") == "NaN") {
      _val = _val.replace(/\-/g, "/");
      _date = new Date(_val.toString());
      return _date;
    }
    else {
      return _date;
    }
  },
  

}





