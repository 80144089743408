<style src="./index.less" lang="less" scoped rel="stylesheet/less"></style>
<template src="./index.html"></template>

<script>
export default {
    name: "baseNewsDetail",
    props: {
        config: {
            type: Object,
            default: null,
        },
    },
    mounted() {},
    activated() {},
    methods: {
        
    },
    data() {
        return {
            
        };
    },
};
</script>

