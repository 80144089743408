import Vue from 'vue'

import publicFn from '../libs/publicFn.js';
import getNetWork from '../libs/getNetWork.js';




// 阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false
//引入字体库
// import iconfontCss from '../styles/iconfont/iconfont.css';
// Vue.use(iconfontCss);



import {
  Image,Backtop,
  Message,  MessageBox, 
  

} from 'element-ui'


import baseHeader from '../components/baseHeader/index.vue';
import baseFooter from '../components/baseFooter/index.vue';
import baseUse from '../components/baseUse/index.vue';
import baseNewsDetail from '../components/baseNewsDetail/index.vue';



const components = [
  
  Image, Backtop,
  baseHeader,
  baseFooter,
  baseUse,
  baseNewsDetail,
  

];




export default {
  install() {
    components.map(component => {
      Vue.component(component.name, component);
    });


    Vue.prototype.$Message = Message;
    Vue.prototype.$confirm = MessageBox.confirm;
    //图片服务器地址    
    Vue.prototype.$BASE_IMG_URL=$BASE_IMG_URL;
    Vue.prototype.$BASE_DOMAIN_URL=$BASE_DOMAIN_URL;
    //获取数据方法
    Vue.prototype.$getNetWork = getNetWork;
    Vue.prototype.$publicFn = publicFn;


  }
}
