import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


//this.$store.dispatch('setShowSelectAddress', true); 设置
//this.$store.getters.get_loginShowState 读取
export default new Vuex.Store({
  state: {
    // 免费试用
    use:false,
    
  },
  mutations: {

    
    SET_use(state, val) {
      state.use = val;
    },

   
  },
  actions: {
    
    set_use({commit}, val) {
      commit('SET_use', val);
    },
  },
  getters: {
   
    get_use: (state) => state.use,

  }
})
