<style src="./index.less" lang="less" scoped rel="stylesheet/less"></style>
<template src="./index.html"></template>

<script>
export default {
  name: "baseFooter",
  props: ["type"],
  mounted() {},
  activated() {
    this.recordNumber = recordNumber;
    this.recordNumberUrl = recordNumberUrl;
  },
  methods: {},
  data() {
    return {
      recordNumber:"",
      recordNumberUrl:""
    };
  }
};
</script>

