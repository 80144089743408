<style src="./index.less" lang="less" scoped rel="stylesheet/less"></style>
<template src="./index.html"></template>

<script>
export default {
    name: "baseHeader",
    props: {
        pageName: {
            type: String,
            default: "",
        },
        //   滚动切换导航样式的阈值
        sTop: {
            type: Number,
            default: 200,
        },
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    activated() {},
    methods: {
        handleScroll() {
            // 页面滚动距顶部距离
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;

            // 浏览器窗口高度
            let windowHeight = document.documentElement.clientHeight;
            // 页面总高度
            let bodyHeight = document.body.clientHeight - windowHeight - 300;

            // console.log(windowHeight,scrollTop);
            var scroll = scrollTop - this.scrollNum;
            this.scrollNum = scrollTop;
            // console.log(scrollTop);
            // // 如果距离超过200 向下滚动为Down 向上为up
            // if (scrollTop > bodyHeight) {
            //   this.scrollType = "Up";
            // } else if (scrollTop > 300) {
            //   if (scroll < 0) {
            //     this.scrollType = "Up";
            //   } else {
            //     this.scrollType = "Down";
            //   }
            // }
            // // 如果距离小于200 向下向上滚动都为Up
            // else {
            //   this.scrollType = "Up";
            // }
            // 滚动距离超过200时为黑色，小于200时为白色
            if (scrollTop > this.sTop) {
                this.headType = "Hei";
            } else {
                this.headType = "Bai";
            }

            // this.hideHead();

            // console.log(this.scrollType);
        },
        showHead() {
            this.headType = "Hei";
            this.classShow = true;
        },
        hideHead() {
            this.classShow = false;
            if (this.scrollNum > this.sTop) {
                this.headType = "Hei";
            } else {
                this.headType = "Bai";
            }
        },
        goto(a, b) {
            let that = this;
            console.log(a, b);

            this.$router.push({ name: a, query: { t: b } });
            if (b == "") {
                window.scrollTo(0, 0);
            } else {
                let _id = "#t-" + this.$route.name + "-" + this.$route.query.t;
                console.log(_id);
                setTimeout(function () {
                    document.querySelector(_id).scrollIntoView(true);
                }, 50);
            }
        },
        openBox() {
            this.boxState = true;
        },
        closeBox() {
            this.boxState = false;
        },
    },
    data() {
        return {
            scrollNum: 0,
            scrollType: "Up",
            headType: "Bai",
            classShow: true,
            boxState: false,
        };
    },
};
</script>

