

/**
 * ajax请求，用于mobile端
 */
import axios from 'axios';

import Vue from 'vue';
import store from '../store/index';
import {router }from '../router';
import publicFn from '../libs/publicFn.js';


let isToLogin = false;

let _axiosConfig = axios.create({
  timeout: 10000,
  
});
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'

_axiosConfig.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // console.log("请求参数",config);
  return config;
}, function (error) {
  // 对请求错误做些什么
  showToast("初始化接口异常");
  console.log("初始化接口异常",error);
});

let showToast=(value)=>{
  publicFn.messageError(value);
}
export default {
  _axios(_data) {
    let that = this;
    let _axiosData = {};
    

    // 域名前缀

    if (_data.domain) {
      _axiosData.url = _data.domain+_data.url;
    }
    else{
      _axiosData.url=Vue.prototype.$BASE_DOMAIN_URL+_data.url;
    }
    // 请求方式，默认get
    if (_data.method) {
      _axiosData.method = _data.method;
    }
    
    // url形式传递的参数
    if (_data.data){
      // _axiosData.data = Vue.prototype.$qs.stringify(_data.data);
      _axiosData.data = _data.data;

    }


    if (_data.params){
      _axiosData.params = _data.params;
    }
    _axiosData.responseType = "json";
    if (_data.responseType){
      _axiosData.responseType = _data.responseType;
    }
    if (_data.transformRequest){
      _axiosData.transformRequest = _data.transformRequest;
    }


    

    
    // 否非请求带token，默认传
    if (_data.isToken == undefined) {
      _data.isToken = true;
    }
    // 否非请求带token，默认传
    if (_data.errorAlert == undefined) {
      _data.errorAlert = true;
    }
    // 是否启用加载框，默认关闭
    if (_data.loading == undefined) {
      _data.loading = true;
    }
    
    if(_data.loading){
      // Vue.prototype.$Spin.show();
      store.dispatch('set_baseLoadModal', true);
    }
  
    // 如果需要传token
    if (_data.isToken) {
      let _token = JSON.parse(localStorage.getItem('token'));

      _axiosData.headers= { 
        Token:_token 
      }
      if(!_token){
        this.notLogin(_data,'');
        return;
      }
      else{
        this._request(_axiosData,_data);
        return;
      }
    }
    else{
      this._request(_axiosData,_data);
    }
  },
  _request(_axiosData,_data){
    let that = this;
    _axiosConfig(_axiosData).then(function(res){


      if(_data.loading){
        // Vue.prototype.$Spin.hide();
        store.dispatch('set_baseLoadModal', false);
      }


      if(_data.successCallback){
        that._successCallback(res,_data);
      }
      if(_data.defaultCallback){
        _data.defaultCallback();
      }
    },(res) => {
      if(_data.loading){
        // Vue.prototype.$Spin.hide();
        store.dispatch('set_baseLoadModal', false);
      }
      if(_data.errorCallback){
        _data._errorCallback(res);
      }else{
        showToast('网络异常!');
      }
      if(_data.defaultCallback){
        _data.defaultCallback(res,_data);
      }


    });
  },
  _successCallback(res,_data){
    let that = this;
    console.log("返回参数",_data,res);
    if(_data.errorAlert){
      if(res.data.code=='0'){
        _data.successCallback(res);
      }
      else if(res.data.code=="400"){
        that.notLogin(_data,res.data.msg);
      }
      else{
        showToast(res.data.msg);
      }
    }
    else{
      _data.successCallback(res);
    }
    

  },
  _errorCallback(res){

  },
  notLogin(_data,msg){
    if (isToLogin) {
			console.log("已阻止短期多次重复登录");
			return;
		}


		isToLogin = true;
		setTimeout(function() {
			isToLogin = false;
		}, 1000)
    if(_data.loading){
      store.dispatch('set_baseLoadModal', false);
    }
    if(typeof(msg)=="undefined"){
      showToast('请重新登录');
    }
    else{
      showToast(msg);
    }

    router.push({path: '/login'});

  }
  

}
