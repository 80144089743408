<style src="./index.less" lang="less" scoped rel="stylesheet/less"></style>
<template src="./index.html"></template>

<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload
    };
  },
  mounted() {
    let that = this;
  },
  activated() {},
  methods: {
    
  },

  data() {
    return {
      a: "",

    };
  }

  ////    创建前
  //    beforeCreate() { console.log("创建前beforeCreate"); },
  ////    创建后
  //    created() { console.log("created创建后"); },
  ////    载入前
  //    beforeMount() { console.log("beforeMount载入前"); },
  ////    载入后
  //    mounted() { console.log("mounted载入后"); },
  ////    更新前
  //    beforeUpdate() { console.log("beforeUpdate更新前"); },
  ////    更新后
  //    updated() { console.log("updated更新后"); },
  ////    keep-alive组件激活时调用。
  //    activated() { console.log("activated-keep-alive组件激活时调用"); },
  ////    keep-alive组件停用时调用。
  //    deactivated() { console.log("deactivated-keep-alive组件停用时调用"); },
  ////    销毁前
  //    beforeDestroy() { console.log("beforeDestroy销毁前"); },
  ////    销毁后
  //    destroyed() { console.log("destroyed销毁后"); },
};
</script>
<style lang="less" rel="stylesheet/less">
@import "../../styles/common.less";
</style>

