import Vue from 'vue'
import App from './components/app/index.vue';
import { router } from './router'
import store from './store'

//全局通用方法
import index from './libs/index'

Vue.use(index)




window.vue = new Vue({
    router,
    store,
    render: h => h(App),
    // / 添加到这里, 这里的render - event和vue.config.js里面的renderAfterDocumentEvent配置名称一致
    // mounted() {
    //     document.dispatchEvent(new Event('render-event'))
    // }
}).$mount('#app');
