import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routers = [
  {
    path: '/index',
    name: 'index',
    component: () => import('./views/index/index.vue'),
    meta: {
      title: "首页-广州浩新科技",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/x1',
    name: 'x1',
    component: () => import('./views/x1/index.vue'),
    meta: {
      title: "产品介绍-浩新餐饮管理系统",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/x2',
    name: 'x2',
    component: () => import('./views/x2/index.vue'),
    meta: {
      title: "产品介绍-浩新娱乐管理系统",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/x3',
    name: 'x3',
    component: () => import('./views/x3/index.vue'),
    meta: {
      title: "产品介绍-浩新智能餐车",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/x4',
    name: 'x4',
    component: () => import('./views/x4/index.vue'),
    meta: {
      title: "产品介绍-浩新智能餐车",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/us',
    name: 'us',
    component: () => import('./views/us/index.vue'),
    meta: {
      title: "关于我们",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('./views/help/index.vue'),
    meta: {
      title: "帮助与支持",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('./views/news/index.vue'),
    meta: {
      title: "新闻资讯",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/news200926',
    name: 'news200926',
    component: () => import('./views/news200926/index.vue'),
    meta: {
      title: "新闻资讯",
      keywords: "",
      description: ""
    },
  },
  {
    path: '/news200925',
    name: 'news200925',
    component: () => import('./views/news200925/index.vue'),
    meta: {
      title: "新闻资讯",
      keywords: "",
      description: ""
    },
  },


  {
    path: '/404',
    name: '404',
    component: () => import('./views/404/index.vue'),
    meta: {
      title: "404",
      keywords: "",
      description: ""
    },
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/',
    redirect: '/index'
  }
]

export const router = new Router({
  routes: routers,
//   mode: 'hash',
  mode: 'history',
});

router.beforeEach((to, from, next) => {

  next();

});
router.afterEach((to, from) => {

  if (!to.meta.keepAlive) {
      if(to.name=="news"){
          return
      }
    if (to.name != from.name) {
      setTimeout(function () {
        window.scrollTo(0, 0)
      }, 100)
    }
  }
  // ;
  document.title = to.meta.title;


});


